<template>
  <transition
    @leave="leave"
  >
  <!-- v-if="!load" -->
    <Loading
      v-if="!load"
      :overflow="true"
    ></Loading>
  </transition>

  <!-- Main content -->
  <TopNav></TopNav>
  <div class="container-fluid mt-nav text-center">
    <div
      data-aos="fade-up"
      data-aos-delay="1500"
      data-aos-duration="500"
    >
      <h1 class="">contact</h1>
    </div>
    <div
      data-aos="fade-up"
      data-aos-delay="1800"
      data-aos-duration="500"
    >
      <h2 class="text-uppercase mt-4">
        tel
      </h2>
      <div class="body-regular">
        +852 3411 8282
      </div>
      <h2 class="text-uppercase mt-4">
        email
      </h2>
      <div class="body-regular">
        citya.space@gmail.com
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
import AOS from 'aos';
import Loading from '@/components/front/LoadingGeneral.vue';
import TopNav from '@/components/front/TopNav.vue';
import 'aos/dist/aos.css';

export default {
  data() {
    return {
      loadTimer: false,
    };
  },
  components: {
    Loading,
    TopNav,
  },
  computed: {
    load() {
      AOS.init({ once: false, disable: 'phone' });
      AOS.refresh();
      return this.loadTimer;
    },
  },
  methods: {
    /**
     * Page Load effects
     */
    leave(el, done) {
      gsap.to(el, {
        duration: 0.3,
        delay: 1,
        opacity: 0,
        ease: 'ease-out',
        onComplete: done,
      });
      setTimeout(() => {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('overflow-hidden');
      }, 1000);
    },
  },
  mounted() {
    console.clear();
    // changing bg color to black
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('bg-primary');

    setTimeout(() => {
      this.loadTimer = true;
    }, 200);
  },
  unmounted() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('bg-primary');
  },
};
</script>
